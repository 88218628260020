import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/banner"
import Hint from "../components/hint"
import URL from "../components/url"
import { Divider, Grid, Icon, Button } from 'semantic-ui-react';
import { useStaticQuery, graphql } from "gatsby"
// import logosquare from '../assets/images/logos/logosquare.svg'
import logosquare_animated from '../assets/images/logos/logosquare_css_c.svg'
import logoinverted from '../assets/images/logos/logoinverted.svg';

import bizSafe from '../assets/images/logos/bizSafe.svg';
import ggbs from '../assets/images/logos/ggbs.svg';
import Img from "gatsby-image"
import useViewPort from '../utils/useViewPort';



const LogoWords = ({ data, children, height = 30 }) => (
	<object type="image/svg+xml" data={data} style={{ height, marginBottom: -10 }}>{children}</object>
);


const ServiceSection = (props) => {
	const { primaryIcon, secondaryIcon, children, title, color = 'red' } = props;
	return (
		<Grid.Column>
			<Icon.Group size='massive'>
				<Icon className={primaryIcon}/>
				<Icon className={secondaryIcon} color={color} corner/>
			</Icon.Group>
			<h3>{title}</h3>
			<p>
				{children}
			</p>
		</Grid.Column>
	);
};

const Services = () => {
	const { isMobile } = useViewPort();
	return (
		<section className="content services">
			<div>
				<h2 style={{ textAlign: 'center' }}>Our Services</h2>
				<Divider/>
				<Grid columns={isMobile ? 1 : 3} style={{textAlign: 'center', marginBottom: '2rem'}} divided={false}>
					<ServiceSection title="Residential Construction" primaryIcon="house damage" secondaryIcon="level up" color="orange">
							Teardown and rebuild of landed property residences that includes terraces, semi-detached,
							detached (bungalows) and strata landed houses (cluster housing).
					</ServiceSection>
					<ServiceSection title="Commercial &amp; Institutional Construction" primaryIcon="industry" secondaryIcon="hard hat" color="yellow">
							Erection of new commercial buildings such as shophouses, offices, factories and warehouses.
							Also include buildings for institutional purposes, such places of worship (temples, mosques).
					</ServiceSection>
					<ServiceSection title="Addition and Alteration (A&amp;A)" primaryIcon="building" secondaryIcon="plus" color="green">
							Major physical renovation of existing building structure. Includes structural extensions,
							conservation works, changes to building facades, construction of staircases,
							and structural strengthening.
					</ServiceSection>
					<ServiceSection title="Reinstatement" primaryIcon="warehouse" secondaryIcon="refresh">
							Reinstatement of premises to original condition, typically due to lease agreement covering retail or commercial offices.
							Usually includes removal of floor finishes, hacking of wall partitions and dismantling of ceiling tops.
					</ServiceSection>
					<ServiceSection title="Civil Engineering" primaryIcon="road" secondaryIcon="hammer" color="grey">
							Maintenance and construction of infrastructure such as roads, driveways, drainage system and landscaping works.
					</ServiceSection>
					<ServiceSection title="Conservation Works" primaryIcon="university" secondaryIcon="shield" color="blue">
							Undertake restoration works to old buildings in compliance with URA regulations.
							Preserving heritage and cultural significance.
					</ServiceSection>
					<ServiceSection title="Carpentry" primaryIcon="bed" secondaryIcon="tools" color="brown">
							Creating custom furnitures and fixtures.
					</ServiceSection>
				</Grid>
				<div className="action">
					<Button basic color='red' as={Link} to={`/services/`}>Find out more</Button>
				</div>
			</div>
		</section>
	);
}
const About = () => {
	return (
		<section className="content about">
			<div>
				<p>Jinmac Pte Ltd is a locally reputable <URL href={`https://www.bca.gov.sg/BCADirectory/Company/Details/199706813H`}>BCA-registered</URL> construction company that specialises in building reconstruction and addition &amp; alterations works (A&amp;A).</p>
				<p>We are a GB1 General Builder licensed for workheads CW01 General Building at grade B2 and CW02 Civil Engineering at C1 grade.</p>
				<p>Our mission is to establish a strong foundation in the construction industry by delivering on our promises to our clients and providing them with a sense of security in entrusting JINMAC to construct a comfortable living or working environment.</p>
			</div>
		</section>
	);
}

const Description = () => {
	const { isMobile } = useViewPort();
	return (
		<section className="content description">
			<div>
				<Grid columns={isMobile ? 1 : 3} style={{textAlign: 'center', marginBottom: '2rem'}} divided={false}>
					<Grid.Column>
						<h3>Strong</h3>
						<p>Our company employs experienced workers with deep knowledge of modern techniques that will ensure the integrity of all structures we built</p>
					</Grid.Column>
					<Grid.Column>
						<h3>Reliable</h3>
						<p>We are a certified <LogoWords data={bizSafe}>BizSAFE STAR</LogoWords> and <LogoWords data={ggbs}>Green And Gracious</LogoWords> construction company with strong track record of adhering to modern-day building standards.</p>
					</Grid.Column>
					<Grid.Column>
						<h3>Experienced</h3>
						<p>We have over 20 years in construction industry in Singapore, with wide range of activities, working on building landed houses, factories and A&amp;A works.</p>
					</Grid.Column>
				</Grid>
				<div className="action">
					<Button basic color='red' as={Link} to={`/contact/`}>Work with us today</Button>
				</div>
			</div>
		</section>
	);
}
const FAQ = () => {
	const { isMobile } = useViewPort();
	return (
		<section className="content faq">
			<div>
				<h2 style={{ textAlign: 'center' }}>FAQ</h2>
				<Divider/>
				<Grid columns={isMobile ? 1 : 3} centered style={{textAlign: 'center', marginBottom: '2rem'}} divided={false}>
					<Grid.Column>
						<h5>What is the difference between Additions &amp; Alterations (A&amp;A) and Reconstruction?</h5>
						<p>Any proposed works that would increase storey height or change in <Hint content='examples of housing forms are semi-detached, detached, and terrace'> housing form </Hint>(semi-detached, terrace, etc) would be considered reconstruction works.</p>
						<p>In addition, it would be considered reconstruction works, if the extent of the works involved 50% or more of Gross Floor Area (GFA), External Walls, or Structural Elements (columns, beams, slabs).</p>
						<p>Source: <URL href={`https://www.ura.gov.sg/Corporate/Data/forms/Explanatory-Notes-New/DC-6`}>URA explanatory note</URL></p>
					</Grid.Column>
					<Grid.Column>
						<h5>What are the key parameters to note when renovating a landed residential property?</h5>
						<p>When renovating a landed residential property, it is important to check if the intended works comply with the development control guidelines such as the required setback of certain building features. You may refer to the URA's <URL href="https://www.ura.gov.sg/Corporate/Guidelines/Development-Control/Residential/Semi-Detached-Houses/Setbacks-from-boundaries"> illustrated guide</URL>.</p>
						<p>Or you may consult us <Link to={'/contact/'}>here</Link>.</p>
						<p>Source: <URL href="https://www.ura.gov.sg/Corporate/Guidelines/Development-Control/Residential/">URA Handbook</URL></p>
					</Grid.Column>
					<Grid.Column>
						<h5>Which renovation works require approval from URA? (For landed property)</h5>
						<p> Additions and alterations (A&amp;A) to your property require approval if they affect the external facade of the building, or result in an increase of the Gross Floor Area (GFA).</p>
						<p>Check if your renovation works are covered under the <URL href="https://www.ura.gov.sg/Corporate/Guidelines/Development-Control/Planning-Permission/using-CORENET-eSS/exemption">Planning Permission Exemption List</URL>. If they are not, you will need to obtain approval from URA.</p>
						<p>Source: <URL href="https://www.ura.gov.sg/Corporate/Guidelines/Development-Control/Planning-Permission/">URA Guidelines and Procedures</URL></p>
					</Grid.Column>
				</Grid>
			</div>
		</section>
	);
}
const CallAction = ({ children, actionText, actionColour = 'red' }) => {
	return (
		<section className="content action">
			<div>
				<h2 style={{ textAlign: 'center' }}>{children}</h2>
				<Divider/>
				<div className="action">
					<Button basic color={actionColour} as={Link} to={`/contact/`}>{actionText}</Button>
				</div>
			</div>
		</section>
	);
}

const IndexPage = (params) => {
  const data = useStaticQuery(graphql`
    query {
      hero1: file(name: {eq: "20200602_151036"}) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            src
            srcSet
            sizes
          }
        }
      }
      hero2: file(name: {eq: "PB100537"}) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            src
            srcSet
            sizes
          }
        }
      }
    }
	`);
	const { isMobile } = useViewPort();
  return (
    <Layout {...params}>
			<div className="main page">

				<SEO title="Home" />
				<div className="hero">
					<Img fluid={data.hero1.childImageSharp.fluid}/>
					<div className="herotext">
						<Img alt="Jinmac Pte Ltd" style={{ maxWidth: 500, width: '50vw'}} imgStyle={{ position: 'relative' }} fluid={{src: logosquare_animated, srcSet: undefined, base64: undefined }}/>
					</div>
				</div>
				<div style={{ background: 'white', borderRadius: '10px 10px 0 0', paddingBottom: 10, margin: '0 auto -10vw', minWidth: isMobile ? 0 : 768, width: isMobile ? '100vw' : '80vw', overflow: 'hidden', position: 'relative', top: '-10vw' }}>
					<Banner actionText={'Get Started'}>Safe, Reliable Construction with <LogoWords data={logoinverted} height={50}>Jinmac</LogoWords></Banner>
					<Services />
				</div>
				<div className="hero">
					<Img fluid={data.hero2.childImageSharp.fluid}/>
					<div className="herotext" style={{ textAlign: 'unset', width: 'unset', background: '#00000094' }}>
						<h2 style={{ fontSize: 50, maxWidth: '50vw', margin: 'auto', paddingLeft: isMobile ? 5 : '10vw' }}>At Jinmac, we want to help you build structure that last</h2>
					</div>
				</div>
				<div style={{ background: 'white', borderRadius: '10px 10px 0 0', paddingBottom: 10, margin: 'auto', minWidth: isMobile ? 0 : 768, width: isMobile ? '100vw' : '80vw', overflow: 'hidden' }}>
					<Banner>Our Team</Banner>
					<About/>
					<Description />
					<FAQ />
					<CallAction actionText="Engage our services">Ready to get started?</CallAction>
				</div>

				
			</div>
    </Layout>
  );
}

export default IndexPage
