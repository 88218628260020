import React from 'react';
// import { Popup } from 'semantic-ui-react';

// popup for certain terminology
// export default class Hint extends React.Component {
//   constructor(props) {
//     super(props);
//     this.element = React.createRef();
//   }

// 	state = { open: false }

// 	hideOnScroll = event => {
//     const element = this.element.current;
// 		if (event.target.contains(element)) {
// 			window.removeEventListener('scroll', this.hideOnScroll, true);
// 			setTimeout(() => this.setState({ open: false }), 50);
// 		}
// 	}

// 	onOpen = () => {
// 		window.addEventListener('scroll', this.hideOnScroll, true);
// 		this.setState({ open: true });
// 	}

// 	handleClose = () => {
// 		this.setState({ open: false });
// 	}

// 	componentWillUnmount() {
// 		window.removeEventListener('scroll', this.hideOnScroll, true);
// 	}

// 	render() {
// 		if (!this.props.content) { return this.props.children; }
// 		return (
// 			<Popup ref={this.element}
// 				trigger={<abbr title={this.props.content}>{this.props.children}</abbr>}
// 				content={this.props.content}
// 				open={this.state.open}
// 				onOpen={this.onOpen}
// 				onClose={this.handleClose}
// 			/>
// 		);
// 	}
// }
const Hint = ({ children, content }) => (
  <abbr title={content}>{children}</abbr>
);

export default Hint;
